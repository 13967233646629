import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import Icon1 from '../../../assets/images/frontend/icon1.svg'
import Icon2 from '../../../assets/images/frontend/icon2.svg'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/styles.css'

const VisionAndMission = () => {
    return (
        <>
            <InnerBanner pageName="Vision and Mission"/>
            <section className="about-us section">
                <div className="container w-70">
                    <div className="row">
                        <h2 className="mb-4 lh-base titleFont title"><img
                            src={Icon1}
                            className="mv-icon"/> Our Mission
                        </h2>
                        <div className="col-md-12  mb-5  text-justify">

                            <p>Our mission is to revive ancient traditions with a modern approach, nurturing talent,
                                promoting cultural exchange, and fostering a spirit of harmony and collaboration
                                across communities and borders. We strive to provide opportunities for artists, cultural
                                enthusiasts, and players to showcase their skills and talents while also promoting
                                understanding and cooperation among diverse cultures. Through our initiatives, we
                                aim to
                                create a platform where creativity thrives, traditions are honoured, and individuals
                                from all
                                walks of life come together to celebrate the power of art, culture, and sports with
                                the motto
                                of unifying nations through arts, cultures and traditional games.</p>

                        </div>
                    </div>
                    <div className="row">
                        <h2 className="mb-4 lh-base titleFont title"><img
                            src={Icon2}
                            className="mv-icon"/> Our Vision

                        </h2>
                        <div className="col-md-12 mb-5 text-justify">

                            <p>The vision of the Modern Pythian Games is to create a global platform, encompassing
                                both
                                digital, virtual, and physical realms, where every artist, cultural enthusiast, and
                                player has
                                the opportunity to participate and showcase their talent at various levels. Our aim
                                is to
                                unite art, culture, traditional games and sports to celebrate diversity, foster
                                unity, and strive
                                for excellence, enriching lives and inspiring generations. We envision bringing
                                arts, culture,
                                and tradition into the organized sector, developing numerous properties for the
                                benefit of
                                artists, players, and promoting tourism, thereby contributing to national economies.
                                Inspired by the 2600-year-old Delphi Peace Accord, we seek to establish various
                                global
                                awards in all segments and create our own media network with a focused agenda on
                                arts,
                                culture, and sports.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default VisionAndMission;