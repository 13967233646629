import React, {useEffect, useRef} from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import map1 from '../../../assets/images/frontend/mapicon/map1.svg';
import map2 from '../../../assets/images/frontend/mapicon/map2.svg';
import map3 from '../../../assets/images/frontend/mapicon/map3.svg';
import map4 from '../../../assets/images/frontend/mapicon/map4.svg';
import map5 from '../../../assets/images/frontend/mapicon/map5.svg';
import map6 from '../../../assets/images/frontend/mapicon/map6.svg';
import map8 from '../../../assets/images/frontend/mapicon/map8.svg';
import map9 from '../../../assets/images/frontend/mapicon/map9.svg';
import map10 from '../../../assets/images/frontend/mapicon/map10.svg';
import map11 from '../../../assets/images/frontend/mapicon/map11.svg';
import map12 from '../../../assets/images/frontend/mapicon/map12.svg';
import map13 from '../../../assets/images/frontend/mapicon/map13.svg';
import map14 from '../../../assets/images/frontend/mapicon/map14.svg';
import map15 from '../../../assets/images/frontend/mapicon/map15.svg';
import map16 from '../../../assets/images/frontend/mapicon/map16.svg';
import map17 from '../../../assets/images/frontend/mapicon/map17.svg';
import map18 from '../../../assets/images/frontend/mapicon/map18.svg';
import map19 from '../../../assets/images/frontend/mapicon/map19.svg';
import map20 from '../../../assets/images/frontend/mapicon/map20.svg';
import map21 from '../../../assets/images/frontend/mapicon/map21.svg';
import map22 from '../../../assets/images/frontend/mapicon/map22.svg';

const Map = () => {
    const mapRef = useRef(null);
    const markersRef = useRef([]);

    useEffect(() => {
        // Initialize the map
        const map = L.map(mapRef.current, {
            center: [10, 0],
            zoom: 3,
            maxBounds: [
                [85, -180], // Northeast corner
                [-85, 180], // Southwest corner
            ],
            zoomControl: false,
            scrollWheelZoom: false,
            doubleClickZoom: false,
            touchZoom: false,
        });

        const cartodbAttribution = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attribution">CARTO</a>';

        L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
            attribution: cartodbAttribution,
        }).addTo(map);

        // Function to add markers
        const addMarkers = () => {
            const markers = [
                {lat: 41, lng: -100.4194, country: 'USA', iconUrl: map17},
                {lat: 56.1304, lng: -106.3468, country: 'Canada', iconUrl: map8},
                {lat: -10, lng: -52, country: 'Brazil', iconUrl: map15},
                {lat: 23.634501, lng: -102.552788, country: 'Mexico', iconUrl: map16},
                {lat: 31.791702, lng: -7.092620, country: 'Morocco', iconUrl: map3},
                {lat: 39.399872, lng: -8.224454, country: 'Portugal', iconUrl: map6},
                {lat: 50.503887, lng: 4.469936, country: 'Belgium', iconUrl: map12},
                {lat: 38.995368, lng: 21.987713, country: 'Greece', iconUrl: map18},
                {lat: 34.982302, lng: 33.145128, country: 'Cyprus', iconUrl: map10},
                {lat: 34.982302, lng: 33.145128, country: 'Ethiopia', iconUrl: map4},
                {lat: 1.441968, lng: 38.431398, country: 'Kenya', iconUrl: map13},
                {lat: 25, lng: 43, country: 'Saudi Arabia', iconUrl: map5},
                {lat: 22.351115, lng: 78.667743, country: 'India', iconUrl: map22},
                {lat: 28.108393, lng: 84.091714, country: 'Nepal', iconUrl: map11},
                {lat: 24.476929, lng: 90.293441, country: 'Bangladesh', iconUrl: map9},
                {lat: 4.569375, lng: 102.265682, country: 'Malaysia', iconUrl: map20},
                {lat: 35.000074, lng: 104.999927, country: 'China', iconUrl: map14},
                {lat: 36.638392, lng: 127.696119, country: 'South Korea', iconUrl: map2},
                {lat: 36.574844, lng: 139.239418, country: 'Japan', iconUrl: map1},
                {lat: 12.750349, lng: 122.731210, country: 'Philippines', iconUrl: map21},
                {lat: -24.776109, lng: 134.755000, country: 'Australia', iconUrl: map19},
            ];

            markers.forEach(marker => {
                const icon = L.icon({
                    iconUrl: marker.iconUrl,
                    iconSize: [32, 32],
                    iconAnchor: [16, 32],
                    popupAnchor: [0, -32],
                });

                const leafletMarker = L.marker([marker.lat, marker.lng], {icon})
                    .bindPopup(`<b>${marker.country}</b>`);

                leafletMarker.addTo(map);  // Add to the map
                markersRef.current.push(leafletMarker);  // Store marker reference
            });
        };

        addMarkers();

        // Handle dragging to restrict horizontal panning
        const handleDrag = (e) => {
            const {target} = e;
            const newCenter = target.getCenter();
            target.setView([newCenter.lat, newCenter.lng]); // Keep longitude fixed at 0
        };

        map.on('drag', handleDrag);

        // Cleanup map on component unmount
        return () => {
            map.off('drag', handleDrag); // Remove event listener
            map.remove();
            markersRef.current.forEach(marker => map.removeLayer(marker)); // Clean up markers
            markersRef.current = []; // Clear marker refs
        };
    }, []);

    return (
        <div>
            <div ref={mapRef} style={{height: '600px', width: '100%'}}></div>
        </div>
    );
};

export default Map;
