import './Home.css'
import Banner from "../../Components/Banner/Banner";
import AboutImage1 from "../../../assets/images/frontend/about-us.png";
import MordernGames from "../../../assets/images/frontend/modern-game.jpg"
import {FaArrowRightLong} from "react-icons/fa6";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/styles.css'
import React, {useEffect, useState} from "react";
import eventCatData from '../../../data/event-categories.json';
import Map from "../../Components/Map/Map";
import {Link, useNavigate} from "react-router-dom";
import eventsApi from "../../Apis/eventsApi";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Box, Modal} from '@mui/material';
import {Close, PlayCircle} from "@mui/icons-material";
import {storagePath} from "../../../assets/utils/soragePath";
import getAssociates from "../../Apis/associatesApi";
import Associates from "../../Components/Assiciates/Associates";

const Home = () => {
    const [activeCategory, setActiveCategory] = useState("Historical");
    const [youtubeType, setActiveYoutubeType] = useState("videos");
    const navigate = useNavigate();
    const [videoData, setVideoData] = useState({open: false, src: '', title: '', shortVideo: false});
    const [upcomingData, setUpcomingData] = useState({});
    const [oldData, setOldData] = useState({});
    const [associatesLogos, setAssociatesLogos] = useState([]);
    const historicalData = [
        {
            title: 'Delphi Economic Forum',
            imageSrc: 'https://res.pythiangames.org/assets/images/video/poster1.jpg',
            videoSrc: 'https://res.pythiangames.org/assets/images/video/Delphi-Economic-Forum.mp4'
        }, {
            title: 'DD promotion',
            imageSrc: 'https://res.pythiangames.org/assets/images/video/dd-poster.png',
            videoSrc: 'https://res.pythiangames.org/assets/images/video/DDPromo.mp4'
        }, {
            title: 'Krishna and Pythian Games',
            imageSrc: 'https://res.pythiangames.org/assets/images/video/poster-krishna.png',
            videoSrc: 'https://res.pythiangames.org/assets/images/video/Krishna-and-Pythian-Games.mp4'
        }, {
            title: 'Greek Embassay',
            imageSrc: 'https://res.pythiangames.org/assets/images/video/greek.png',
            videoSrc: 'https://res.pythiangames.org/assets/images/video/Greek-Embassay.mp4'
        }, {
            title: 'Pythian Games Festival 2023',
            imageSrc: 'https://res.pythiangames.org/assets/images/video/pythian.png',
            videoSrc: 'https://res.pythiangames.org/assets/images/video/Pythian-Games-Festival-2023.mp4'
        },]
    const memorialData = [
        {
            title: '3rd day Boxing young boy performance',
            imageSrc: 'https://res.pythiangames.org/assets/images/video/3rd-day-Boxing.mp4',
            videoSrc: 'https://res.pythiangames.org/assets/images/video/3rd-day-Boxing.mp4',
        }, {
            title: '3rd day  Football match  performance',
            imageSrc: 'https://res.pythiangames.org/assets/images/video/Football-match-performance.mp4',
            videoSrc: 'https://res.pythiangames.org/assets/images/video/Football-match-performance.mp4',
        }, {
            title: '3rd day dance performance',
            imageSrc: 'https://res.pythiangames.org/assets/images/video/dance-1.mp4',
            videoSrc: 'https://res.pythiangames.org/assets/images/video/dance-1.mp4',
        }, {
            title: '3rd day Raag tilak with Harmonium  performance',
            imageSrc: 'https://res.pythiangames.org/assets/images/video/Harmonium-performance.mp4',
            videoSrc: 'https://res.pythiangames.org/assets/images/video/Harmonium-performance.mp4',
        }, {
            title: '3rd day Authar performance',
            imageSrc: 'https://res.pythiangames.org/assets/images/video/Authar-performance.mp4',
            videoSrc: 'https://res.pythiangames.org/assets/images/video/Authar-performance.mp4',
        },]
    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 3000}, items: 4
        }, desktop: {
            breakpoint: {max: 3000, min: 1024}, items: 4
        }, tablet: {
            breakpoint: {max: 768, min: 464}, items: 2
        }, mobile: {
            breakpoint: {max: 464, min: 0}, items: 1
        }
    };
    const fetchEvent = async () => {
        try {
            const response = await eventsApi();
            setUpcomingData(response[0])
            setOldData(response[1])
        } catch (e) {
            console.error('Error: ', e);
        }
    }
    const fetchAssocieates = async () => {
        try {
            const response = await getAssociates('home');
            response.map((item) => {
                if (item.logo != "") {
                    associatesLogos.push(item.logo)
                }
            })
        } catch (e) {
            console.error('Error: ', e);
        }
    }
    const VideoCardView = ({title, imgSrc, videoSrc, shortVideo, videoBanner}) => {
        return (<div className='carousel-video-wrapper'>
            <figure className='d-flex justify-content-center'>
                {videoBanner ? (<video autoPlay={false} style={{width: '35%'}}>
                    <source src={videoSrc}/>
                </video>) : (<img src={imgSrc} alt={title} title={title}/>)}
            </figure>
            <figcaption>{title}</figcaption>
            <PlayCircle className='play-icon'
                        onClick={() => setVideoData({
                            open: true, title: title, src: videoSrc, shortVideo: shortVideo
                        })}/>
        </div>)
    }
    useEffect(() => {
        fetchEvent()
        fetchAssocieates()
        const script = document.createElement('script');
        script.src = "https://snapwidget.com/js/snapwidget.js";
        script.async = true;
        document.body.appendChild(script);
    }, [])
    const handleCloseModal = () => {
        setVideoData({open: false, title: '', src: ''})
    }
    return (
        <>
            <Modal
                open={videoData.open}
                onClose={() => handleCloseModal()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box id='video-box'>
                    <div style={{position: 'relative'}}>
                        <video autoPlay='1' controls style={{width: videoData.shortVideo ? '35%' : '100%'}}>
                            <source src={videoData.src}/>
                        </video>
                        <Close className='close-icon' onClick={() => handleCloseModal()} titleAccess='Close Video'/>
                    </div>
                    {/*<span style={{color: '#fff', margin: '0 0 20px 15px ', fontSize: '12pt'}}>{videoData.title}</span>*/}
                </Box>
            </Modal>

            <Banner/>
            {/****************************   ABOUT SECTION   *******************************/}

            <section className='about about-circle'>
                <div className="container position-relative">
                    <div className="row flex-md-row-reverse mt-5">
                        <div className="about-img col-md-12 col-lg-6 col-12">
                            <img style={{width: '100%', zIndex: '1', position: 'relative'}} src={AboutImage1}
                                 alt="About image"/>
                        </div>
                        <div className="col-md-12 col-lg-6 col-12">
                            <h2 className="mt-md-3 mt-lg-0 titleFont title">ABOUT PYTHIAN GAMES</h2>
                            <p className="mt-md-3 pe-md-3 p-0 text-justify" style={{fontSize: '14px'}}>
                                Pythian Games, along with the Olympic Games, were part of the four Panhellenic Games in
                                Ancient Greece. The Pythian Games included various competitions such as music, dancing,
                                singing, painting, poetry, chariot racing, wrestling, and athletics, while the Olympics
                                were
                                dedicated solely to physical sports. The Pythian Games were held every four years at
                                Delphi
                                in honour of the god Apollo and flourished alongside the Olympics from around 582 BC
                                until
                                they were discontinued in 394 A.D. The Pythian Games were nearly as significant as the
                                Olympics. What made the Pythian Games special was the participation of women in some
                                competitions at that time. Winners were awarded a wreath made of bay laurel leaves as a
                                prize.</p>
                            <blockquote className="blockquote">
                                “The focused agenda of Modern Pythian Games is to be single global umbrella for Arts,
                                culturals and traditional Games”
                                <p className='mt-1'>
                                    <strong>- Bijender Goel, Founder, Modern Pythian Games</strong>
                                </p>
                            </blockquote>

                            <div className="more-btn mt-4 d-inline-block">
                                <Link to='/about-us'>
                                    <span>Read More</span>
                                    <FaArrowRightLong/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="about-img col-md-12 col-lg-6 col-12">
                            <img style={{width: '100%'}} src={MordernGames} alt="Mordern Games"/>
                        </div>
                        <div className="col-md-12 col-lg-6 col-12">
                            <h2 className="mt-md-3 mt-lg-0 titleFont title">MODERN PYTHIAN GAMES</h2>
                            <p className="mt-md-3 pe-md-3 p-0 text-justify" style={{fontSize: '14px'}}>Modern Pythian
                                Games,
                                envisioned by Bijender
                                Goel, aim to revive
                                the
                                traditions of the ancient Pythian Games in the modern world. These games strive to reach
                                every
                                artist and player from across the globe and ensure their participation
                                through digital technology and global networks.</p>
                            <p style={{fontSize: '14px'}}>Modern Pythian Games are the only worldwide platform for
                                artists
                                and
                                players where they
                                have
                                the
                                opportunity to showcase their talents to the world, representing their countries in the
                                fields
                                of
                                arts, cultures, and traditional games either
                                individually or in groups. The modern Pythian Games cover a wide range of categories,
                                including
                                Musical Arts, performing arts, visual arts, Social Arts, Language Arts, Ecological Arts,
                                Digital
                                Arts, Martial Arts, traditional games,
                                e-sports, adventure games, water sports, and more. This inclusive program ensures more
                                opportunities
                                for diverse talents worldwide.</p>

                            <div className="more-btn mt-4 d-inline-block">
                                <Link to='/modern-pythian-games'>
                                    <span>Read More</span>
                                    <FaArrowRightLong/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/****************************   CAROUSEL SECTION   *******************************/}
            <div className='section'>
                <div className='container'>
                    <div className="mt-3">
                        <ul className="categoryList">
                            <li className={activeCategory === "Historical" ? "active-category" : ''}
                                onClick={() => setActiveCategory('Historical')}>
                                Our Historical
                            </li>
                            <li className={activeCategory === "Memorable" ? "active-category" : ''}
                                onClick={() => setActiveCategory('Memorable')}>
                                Memorable Events
                            </li>
                        </ul>

                        <div className='mt-3'>
                            {activeCategory === "Historical" ? (<Carousel responsive={responsive}>
                                {historicalData.map((item, index) => (
                                    <VideoCardView title={item.title} imgSrc={item.imageSrc} videoSrc={item.videoSrc}
                                                   shortVideo={false} videoBanner={false}/>))}
                            </Carousel>) : <Carousel responsive={responsive}>
                                {memorialData.map((item, index) => (
                                    <VideoCardView title={item.title} imgSrc={item.videoSrc}
                                                   videoSrc={item.videoSrc}
                                                   shortVideo={true}
                                                   videoBanner={true}/>))}
                            </Carousel>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/********************************************** Events Section ******************************************************/}
            <section className='container'>
                <div className="inner-container-small text-start mb-3 mb-md-5">
                    <h2 className="text-center mb-12 mb-lg-5 lower-text">Our Associates</h2>
                    <h1 className="large-text text-center upper-text">Associates</h1>
                </div>
                {
                    <Associates logos={associatesLogos}/>
                }
            </section>
            {/********************************************** Events Section ******************************************************/}

            <section className='container'>
                <div className="inner-container-small text-start mb-3 mb-md-5">
                    <h2 className="text-center mb-12 mb-lg-5 lower-text">Events</h2>
                    <h1 className="large-text text-center upper-text">Events</h1>
                </div>
                <div className="row mb-4 mb-md-5 flex-row-reverse">
                    <div className="col-12 col-md-12 col-lg-6 mb-4 mb-lg-0 p-3">
                        <div className="box-shadow p-20 card-shadow">
                            <h5 className="mb-3">{upcomingData.headingtext}</h5>
                            <div className="event-wrapper">
                                <div className="card2 radius-0 mb-0 text-start border-0 card-lift">
                                    <div className="card-body">
                                        <div>
                                            <figure>
                                                <img className="w-100"
                                                     src={storagePath + upcomingData.eventimage}
                                                     alt='Event image'/>
                                            </figure>
                                        </div>
                                        <div className="card-content">
                                            <p className="small">
                                                <svg width="21" height="21"
                                                     viewBox="0 0 21 21" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M10.5 11.8125C9.85103 11.8125 9.21664 11.6201 8.67704 11.2595C8.13744 10.899 7.71687 10.3865 7.46852 9.78693C7.22017 9.18736 7.15519 8.52761 7.2818 7.89111C7.40841 7.25461 7.72092 6.66995 8.17981 6.21106C8.6387 5.75217 9.22336 5.43966 9.85986 5.31305C10.4964 5.18644 11.1561 5.25142 11.7557 5.49977C12.3553 5.74812 12.8677 6.16869 13.2283 6.70829C13.5888 7.24789 13.7813 7.88228 13.7813 8.53125C13.7802 9.40117 13.4342 10.2352 12.819 10.8503C12.2039 11.4654 11.3699 11.8115 10.5 11.8125ZM10.5 6.5625C10.1106 6.5625 9.72998 6.67797 9.40622 6.8943C9.08246 7.11063 8.83012 7.4181 8.68111 7.77784C8.5321 8.13759 8.49312 8.53344 8.56908 8.91534C8.64505 9.29724 8.83255 9.64803 9.10789 9.92337C9.38322 10.1987 9.73402 10.3862 10.1159 10.4622C10.4978 10.5381 10.8937 10.4991 11.2534 10.3501C11.6132 10.2011 11.9206 9.94879 12.137 9.62503C12.3533 9.30127 12.4688 8.92063 12.4688 8.53125C12.4682 8.00927 12.2606 7.50881 11.8915 7.13971C11.5224 6.77061 11.022 6.56302 10.5 6.5625Z"
                                                        fill="#0FA958"></path>
                                                    <path
                                                        d="M10.5 19.6875L4.96388 13.1585C4.88696 13.0604 4.81083 12.9618 4.73551 12.8625C3.78984 11.6168 3.27897 10.0952 3.28126 8.53125C3.28126 6.61672 4.0418 4.7806 5.39558 3.42682C6.74936 2.07304 8.58548 1.3125 10.5 1.3125C12.4145 1.3125 14.2507 2.07304 15.6044 3.42682C16.9582 4.7806 17.7188 6.61672 17.7188 8.53125C17.721 10.0945 17.2104 11.6154 16.2652 12.8605L16.2645 12.8625C16.2645 12.8625 16.0676 13.1211 16.0381 13.1558L10.5 19.6875ZM5.78354 12.0717C5.78354 12.0717 5.93645 12.2738 5.97123 12.3172L10.5 17.6584L15.0347 12.3099C15.0636 12.2738 15.2171 12.0704 15.2178 12.0697C15.9903 11.052 16.4078 9.80899 16.4063 8.53125C16.4063 6.96482 15.784 5.46254 14.6764 4.3549C13.5687 3.24726 12.0664 2.625 10.5 2.625C8.93357 2.625 7.43129 3.24726 6.32366 4.3549C5.21602 5.46254 4.59376 6.96482 4.59376 8.53125C4.59238 9.80978 5.01033 11.0535 5.78354 12.0717Z"
                                                        fill="#0FA958"></path>
                                                </svg>
                                                {upcomingData.landmark}, {upcomingData.resaddress}, &nbsp;
                                                {upcomingData.statename}
                                            </p>
                                            <h3>{upcomingData.eventname}</h3>
                                            <a href={upcomingData.buttonurl}
                                               className="action-button mt-2 small-btn"><span></span>
                                                <i>{upcomingData.buttontext}</i>
                                            </a><a className="activeLink themeColor"
                                                   onClick={() => navigate(`/event/${upcomingData.readmoreurl}`, {
                                                       state: {
                                                           eventid: upcomingData.eventaid,
                                                           eventname: upcomingData.eventname
                                                       }
                                                   })}>Read
                                            More <svg width="21" height="21"
                                                      viewBox="0 0 21 21" fill="none"
                                                      xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.75 10.5H20.25M20.25 10.5L15 15.75M20.25 10.5L15 5.25"
                                                    stroke="#0FA958" stroke-width="1.29" stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                            </svg></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 old-event p-3">
                        <div className="box-shadow p-20 card-shadow">
                            <h5 className="mb-3">{oldData.eventname}</h5>
                            <iframe src={oldData.eventimage}
                                    title="alt" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen="true"></iframe>
                        </div>
                    </div>
                </div>
            </section>

            {/********************************************** Event Category Section ******************************************************/}

            <section className="eventCategorySection">
                <div className="container">
                    <div className="inner-container-small text-start mb-3 mb-md-5">
                        <h2 className="text-center mb-3 mb-lg-5 lower-text">Event Categories</h2>
                        <h1 className="large-text text-center upper-text">Event Categories</h1>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row align-items-center ">
                                {
                                    Object.keys(eventCatData).map((key, index) => (
                                        <div
                                            className="col-6 col-sm-6 col-md-6 col-lg-3 import mb-3 mb-md-5 import ">
                                            <div className="card1"
                                                 onClick={() => navigate(`/event-categories/${key}`)}>
                                                <img src={storagePath + eventCatData[key].image_url}
                                                     className="rounded-3 " alt=" "/>
                                                <div className="card-text ">
                                                    <img src={storagePath + eventCatData[key].icon_url}
                                                         alt="icon image"/>
                                                    <p className="mt-12 ">{eventCatData[key].title}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/********************************************** YouTube Video Section ******************************************************/}

            {/*
         <section className="section">
         <div className="container">
         <div className="inner-container-small text-start mb-3 mb-md-5">
         <h2 className="text-center mb-3 mb-lg-5 ">Youtube Channel- Latest Update</h2>
         <h1 className="large-text text-center ">Youtube Channel Videos</h1>
         </div>
         <div>
         <div className='d-flex justify-content-between align-items-center'>
         <ul className="categoryList">
         <li className={youtubeType === "videos" ? "active-category" : ''}
         onClick={() => setActiveYoutubeType('videos')}>
         Videos
         </li>
         <li className={youtubeType === "shorts" ? "active-category" : ''}
         onClick={() => setActiveYoutubeType('shorts')}>
         Shorts
         </li>
         </ul>
         <a href='https://www.youtube.com/@modernpythiangames/videos' target='_blank'
         style={{ color: '#0aa555', textDecoration: 'none', fontWeight: 500 }}>View all</a>
         </div>

         <div className='mt-3'>
         {youtubeType === "videos" ? 'No videos found' : 'No shorts found'}
         </div>
         </div>
         </div>
         </section>*/}

            {/********************************************** Founder Desk Section ******************************************************/}

            <section className="section">
                <div className="container">
                    <div className="inner-container-small text-start mb-3 mb-md-5">
                        <h2 className="text-center mb-3 mb-lg-5 ">
                            Founder Desk</h2>
                        <h1 className="large-text text-center ">Founder Desk</h1>
                    </div>
                    <h2 className="mb-4 lh-base titleFont text-center title">Instagram</h2>
                    {/*<div className="elfsight-app-bd210afb-4144-4a9a-a93a-eacbc3e6b935" data-elfsight-app-lazy></div>*/}
                    <iframe
                        src="https://snapwidget.com/embed/1082162"
                        className="snapwidget-widget"
                        allowTransparency="true"
                        frameBorder="0"
                        scrolling="no"
                        style={{border: 'none', overflow: 'hidden', width: '100%'}}
                        title="Posts from Instagram"
                    />
                </div>
            </section>

            {/********************************************** Map Section ******************************************************/}

            <section className="map section">
                <div className="container">
                    <div className="inner-container-small text-start mb-3 mb-md-5">
                        <h2 className="text-center mb-3 mb-lg-5 ">
                            Global Cultural Moment</h2>
                        <h1 className="large-text text-center ">Nation Members</h1>
                    </div>

                </div>
                <Map/>
            </section>
        </>
    )
}
export default Home;
