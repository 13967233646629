import './App.css';
import Header from "./app/Components/Header/Header";
import './assets/font/Font.css'
import Home from "./app/Pages/Home/Home";
import React, {useEffect, useState} from "react";
import Footer from "./app/Components/Footer/Footer";
import {Route, Routes, useLocation} from "react-router-dom";
import Founder from "./app/Pages/Founder/Founder";
import Event from "./app/Pages/Event/Event";
import VisionAndMission from "./app/Pages/VisionAndMission/VisionAndMission";
import AboutUs from "./app/Pages/AboutUs/AboutUs";
import ModernPythianGames from "./app/Pages/ModernPythianGames/ModernPythianGames";
import AimAndObjectives from "./app/Pages/AimAndObjectives/AimAndObjectives";
import History from "./app/Pages/History/History";
import Contact from "./app/Pages/Contact/Contact";
import News from "./app/Pages/News/News";
import Donation from "./app/Pages/Donation/Donation";
import {useMediaQuery, useTheme} from "@mui/material";
import NewsDetails from "./app/Pages/NewsDetails/NewsDetails";
import Membership from './app/Pages/Membership/Membership';
import OurCouncil from './app/Pages/OurCouncil/OurConsil';
import PartnerDetails from './app/Pages/PartnerDetails/PartnerDetails';
import EventCategories from "./app/Pages/EventCategories/EventCategories";
import EventSubCategories from "./app/Pages/EventSubCategories/EventSubCategories";
import tracking from "./app/Apis/trackingApi";
import EventDetails from "./app/Pages/EventDetails/EventDetails";
import MetaData from "./app/Components/MetaData";
import {HelmetProvider} from "react-helmet-async";

function App() {

  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [scrolled, setScrolled] = React.useState(false);
  const [tid, setTID] = useState('');
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }
  const handleTracking = async () => {
    try {
      const response = await tracking(document.referrer, window.location.href, window.location.pathname);
      setTID(response[0].encrstr)
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    handleTracking()
  }, [location]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [])

  return (
    <HelmetProvider>
      <div className="App" style={{backgroundColor: '#f9fafd'}}>
        <Header isScrolled={scrolled}/>
        <div style={{marginTop: isMobile ? '100px' : ''}}>
          <MetaData/>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/event" element={<Event/>}/>
            <Route path="/event/:eventpath" element={<EventDetails tid={tid}/>}/>
            <Route path="/history" element={<History/>}/>
            <Route path="/vision-and-mission" element={<VisionAndMission/>}/>
            <Route path="/about-us" element={<AboutUs/>}/>
            <Route path="/modern-pythian-games" element={<ModernPythianGames/>}/>
            <Route path="/aim-and-objectives" element={<AimAndObjectives/>}/>
            <Route path="/founders-message" element={<Founder/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/our-council" element={<OurCouncil/>}/>
            <Route path="/our-council/:slug" element={<PartnerDetails/>}/>
            <Route path="/membership" element={<Membership/>}/>
            <Route path="/event-categories/:event" element={<EventCategories/>}/>
            <Route path="/event-categories/:event/:page" element={<EventSubCategories/>}/>
            <Route path="/news" element={<News/>}/>
            <Route path="/news/details" element={<NewsDetails/>}/>
            <Route path="/donation" element={<Donation/>}/>
          </Routes>
        </div>
        <Footer/>
      </div>
    </HelmetProvider>
  );
}

export default App;
