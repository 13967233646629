// src/components/Donation/Donation.js

import React, {useState} from 'react';
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import {Alert, Button, Form, Modal} from 'react-bootstrap';
import QRcode from '../../../assets/images/frontend/qr-code.png';
import pdf1 from '../../../assets/images/doc/Approval-Letter-for-form-CSR1.pdf';
import pdf2 from '../../../assets/images/doc/Delphic-India-Trust-12A-Registration.pdf';
import axios from 'axios';
import './Donation.css';
import {donationPath} from "../../../assets/utils/donationPath"; // Your custom CSS

const Donation = () => {
  return (
    <>
      <InnerBanner pageName="Donation"/>
      <section className="post section">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mx-auto">
              <h2 className="text-center lh-base titleFont title">Donate Now</h2>
              <p className="mb-5 text-center">
                Alone we can do so little, together we can do so much
              </p>
              <div className="mt-4 text-center qr">
                <img src={QRcode} alt="QR Code" className="scan"/>
              </div>
              <div className="details mt-4 text-start">
                <dl className="row">
                  <dt className="col-sm-5 col-12">Bank Name</dt>
                  <dd className="col-sm-7 col-12">UCO Bank</dd>

                  <dt className="col-sm-5 col-12">Branch Name</dt>
                  <dd className="col-sm-7 col-12">South Patel Nagar, New Delhi</dd>

                  <dt className="col-sm-5 col-12">Account Number</dt>
                  <dd className="col-sm-7 col-12">10210210003114</dd>

                  <dt className="col-sm-5 col-12">Account Type</dt>
                  <dd className="col-sm-7 col-12">Current Account</dd>

                  <dt className="col-sm-5 col-12">IFSC Code</dt>
                  <dd className="col-sm-7 col-12">UCBA0001021</dd>
                </dl>
              </div>
              <button onClick={() => window.location.href = donationPath} className="w-100 register mt-4">
                Donate Now
              </button>
              <div className="links">
                <ul className="d-flex">
                  <li className="mb-2">
                    <a
                      href={pdf1}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-link"
                    >
                      Eligible for CSR
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      href={pdf2}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-link"
                    >
                      Registered under 12A and 80G
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Donation;
